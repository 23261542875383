<template>
  <div class="auth-methods">
    <div class="flex alignCenter category">
      <div class="label">认证身份类别</div>

      <el-radio-group v-model="workbenchUserRole">
        <el-radio :label="0">我是法人</el-radio>
        <el-radio :label="1">我是代理人</el-radio>
      </el-radio-group>
    </div>

    <div class="subtitle">{{ workbenchUserRole === 0 ? '企业法定代表人信息' : '代理人实名信息' }}</div>

    <Form ref="paramsForm" class="form-box w-540" disabled :configs="formConfigs" :labelWidth="labelWidth" :paramsData="paramsData"></Form>

    <el-button type="primary" class="el-ls-btns btn" @click="onNext">下一步</el-button>
  </div>
</template>

<script>
/**
 * 企业认证/认证方式
 */
import Form from '@/components/common/Form'
import { realNameInfo } from '@/api/interface/account'

export default {
  name: 'AuthMethods',
  components: {
    Form
  },
  props: {
    category: {
      type: Number,
      default: 0
    }
  },
  model: {
    prop: 'category',
    event: 'changeCategory'
  },
  data() {
    return {
      loading: false,
      workbenchUserRole: this.category, //身份类别，0是法人，1是代理人
      labelWidth: 162,
      formConfigs: [
        {
          type: 'Input',
          prop: 'name',
          label: '姓名',
          placeholder: '请输入姓名',
          rules: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { pattern: /^[\u4e00-\u9fa5]+$/, message: '只能输入中文', trigger: 'blur' }
          ]
        },
        {
          type: 'Select',
          prop: 'type',
          label: '证件类型',
          rules: { required: true, message: '请选择证件类型', trigger: 'change' },
          opction: [
            {
              label: '居民身份证',
              value: 0
            }
          ]
        },
        {
          type: 'Input',
          prop: 'idCard',
          label: '证件编号',
          placeholder: '请输入证件编号',
          rules: [
            { required: true, message: '请输入证件编号', trigger: 'blur' }
            // {
            //   pattern:
            //     /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
            //   message: '输入正确的证件编号',
            //   trigger: 'blur'
            // }
          ]
        }
      ],
      paramsData: {}
    }
  },
  watch: {
    workbenchUserRole: {
      handler(newVal) {
        this.$emit('changeCategory', newVal)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //  下一步
    onNext() {
      if (!this.loading) {
        this.$emit('submit')
      }
    },
    // 获取用户实名信息
    getRealNameInfo() {
      if (this.loading) return
      this.loading = true
      realNameInfo()
        .then(res => {
          const { name = '', idCard = '' } = res || {}
          this.paramsData = {
            name: name || '',
            idCard: idCard || '',
            type: 0
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    this.getRealNameInfo()
  }
}
</script>

<style lang="scss" scoped>
.auth-methods {
  .category {
    margin-top: 73px;
    .label {
      width: 152px;
      font-size: 14px;
      color: #3d3d3d;
      text-align: right;
      margin-right: 47px;
    }

    &:deep(.el-radio__label) {
      color: #3d3d3d;
    }

    &:deep(.el-radio__input.is-checked .el-radio__inner) {
      border-color: #1d8db9;
      background: #1d8db9;
    }
  }

  .subtitle {
    margin: 66px 0 30px 0;
  }
}
</style>
