<template>
  <div class="auth-methods">
    <Form ref="paramsForm" class="form-box" :configs="formConfigs" :labelWidth="labelWidth" :paramsData="paramsData">
      <template v-slot:infoTitle>
        <div class="subtitle">企业基本信息</div>
      </template>

      <!-- 营业执照 -->
      <template v-slot:businessLicenseFile>
        <UploadImg ref="businessLicenseFile" @onChange="handleBusinessLicenseOnChange"></UploadImg>
      </template>

      <template v-slot:businessLicenseHint>
        <div class="annotation">
          1.请上传加盖公章的（营业执照）扫描件；
          <br />
          2.支持图片格式包含JPG.JPEG.PNG.大小不超过5M；
          <br />
          3.上传图片需要清晰完整；
        </div>
      </template>

      <!-- 企业代理授权书 -->
      <template v-slot:powerOfAttorneyFile>
        <div class="flex alignCenter">
          <UploadImg ref="uploadTemplate" @onChange="handlePowerOfAttorneyOnChange"></UploadImg>
          <a class="template" :href="downUrl" download>企业代理授权书模板下载</a>
        </div>
      </template>

      <template v-slot:powerOfAttorneyHint>
        <div class="annotation">
          1. 请下载企业代理授权书模板；
          <br />
          2. 打印后按要求填写并扫描加盖公章后上传；
          <br />
          3. 支持的文件格式为JPG、JPEG、PNG，大小不能超过5M；
          <br />
          4. 上传的文件需要清晰完整
        </div>
      </template>

      <template v-slot:contactTitle>
        <div class="flex alignCenter contacts contacts-end">
          <div class="subtitle">企业联系人信息</div>
          <div class="hint-wrap">
            <i class="el-icon-warning fail-color"></i>
            <span class="hint-text">企业联系人信息仅用于接收平台的部分服务通知，在完成认证后也可进行信息的变更操作。</span>
          </div>
        </div>
      </template>
    </Form>

    <el-button type="primary" class="el-ls-btns btn" @click="onNext">下一步</el-button>
  </div>
</template>

<script>
/**
 * 企业认证/认证方式
 */
import Form from '@/components/common/Form'
import UploadImg from '@/components/common/UploadImg'
import { mapGetters } from 'vuex'

const firmInfo = [
  {
    type: 'SlotItem',
    prop: 'infoTitle'
  },
  {
    type: 'Input',
    prop: 'name',
    label: '企业名称',
    placeholder: '请输入企业名称',
    require: null,
    maxlength: 20,
    rules: { required: true, message: '请输入企业名称', trigger: ['blur', 'change'] }
  },
  {
    type: 'Input',
    prop: 'creditCode',
    label: '统一社会信用代码',
    placeholder: '请输入18位统一社会信用代码',
    require: null,
    maxlength: 18,
    rules: [
      { required: true, message: '请输入统一社会信用代码', trigger: 'blur' },
      { min: 18, max: 18, message: '请输入18位统一社会信用代码', trigger: 'blur' }
    ]
  },
  {
    type: 'Slot',
    prop: 'businessLicenseFile',
    label: '营业执照',
    require: true,
    rules: { required: true, message: '请上传营业执照', trigger: ['blur', 'change'] }
  },
  {
    type: 'Slot',
    prop: 'businessLicenseHint',
    label: '',
    require: null
  }
]

const contacts = [
  {
    type: 'SlotItem',
    prop: 'contactTitle'
  },
  {
    type: 'Input',
    prop: 'contact',
    label: '联系人姓名',
    placeholder: '请输入联系人姓名',
    require: null,
    maxlength: 20,
    rules: { required: true, message: '请输入联系人姓名', trigger: 'blur' }
  },
  {
    type: 'Input',
    prop: 'phone',
    label: '联系人手机',
    placeholder: '请输入11位手机号码',
    require: null,
    rules: [
      { required: true, message: '请输入联系人手机', trigger: 'blur' },
      {
        pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
        message: '请输入正确的手机号',
        trigger: 'blur'
      }
    ]
  },
  {
    type: 'Input',
    prop: 'email',
    label: '联系人邮箱',
    placeholder: '请输入电子邮箱地址',
    require: null,
    rules: [
      { required: true, message: '请输入联系人邮箱', trigger: 'blur' },
      { pattern: /^[a-zA-Z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确的邮箱格式', trigger: 'blur' }
    ]
  }
]

const lawPerson = [
  {
    type: 'Input',
    prop: 'lawName',
    label: '企业法人姓名',
    placeholder: '请输入法人姓名',
    require: null,
    maxlength: 20,
    rules: { required: true, message: '企业法人姓名', trigger: 'blur' },
    disabled: true
  }
]

const legalPerson = [
  {
    type: 'Input',
    prop: 'corporation',
    label: '企业法定代表人姓名',
    placeholder: '请输入法人姓名',
    require: null,
    maxlength: 20,
    rules: { required: true, message: '请输入企业法定代表人姓名', trigger: 'change' }
  },
  {
    type: 'Slot',
    prop: 'powerOfAttorneyFile',
    label: '企业代理授权书',
    require: true,
    rules: { required: true, message: '请上传企业代理授权书', trigger: 'change' }
  },
  {
    type: 'Slot',
    prop: 'powerOfAttorneyHint',
    label: '',
    require: null
  }
]

export default {
  name: 'EnterpriseInfo',
  components: {
    Form,
    UploadImg
  },
  props: {
    // 身份类别，0是法人，1是代理人
    workbenchUserRole: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    businessLicenseFile: null, // 营业执照
    powerOfAttorneyFile: null, // 企业代理授权书
    labelWidth: 180,
    paramsData: {
    }
  }),
  computed: {
    ...mapGetters(["userInfo"]),
    formConfigs() {
      let configs = firmInfo
      if(this.workbenchUserRole === 0) {
        this.paramsData.lawName = this.userInfo.realName || '';
        configs = configs.concat(lawPerson);
      }else if (this.workbenchUserRole === 1) {
        configs = configs.concat(legalPerson)
      }
      configs = configs.concat(contacts)
      return configs
    },
    downUrl() {
      const { origin, pathname } = window.location || {}
      return `${origin}${pathname}企业代理授权书模板.docx`
    }
  },
  methods: {
    // 监听营业执照变化
    handleBusinessLicenseOnChange(fileList) {
      const file = (fileList && fileList[0]) || null
      this.businessLicenseFile = file || null
      const formData = this.$refs['paramsForm'].getFormData()
      this.paramsData = {
        ...formData,
        businessLicenseFile: fileList
      }
      this.$nextTick(() => {
        this.$refs['paramsForm'].validateField(['businessLicenseFile'])
      })
    },
    // 监听企业代理授权书变化
    handlePowerOfAttorneyOnChange(fileList) {
      const file = (fileList && fileList[0]) || null
      this.powerOfAttorneyFile = file || null
      const formData = this.$refs['paramsForm'].getFormData()
      this.paramsData = {
        ...formData,
        powerOfAttorneyFile: fileList
      }
      this.$nextTick(() => {
        this.$refs['paramsForm'].validateField(['powerOfAttorneyFile'])
      })
    },
    // 下一步
    onNext() {
      this.$refs['paramsForm'].submitForm().then(res => {
        this.$emit('submit', res, this.businessLicenseFile, this.powerOfAttorneyFile)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-methods {
  .category {
    margin-top: 73px;
    .label {
      width: 152px;
      font-size: 14px;
      color: #3d3d3d;
      text-align: right;
      margin-right: 47px;
    }

    &:deep(.el-radio__label) {
      color: #3d3d3d;
    }

    &:deep(.el-radio__input.is-checked .el-radio__inner) {
      border-color: #1d8db9;
      background: #1d8db9;
    }
  }

  .subtitle {
    margin: 83px 0 30px 0;
  }

  .contacts {
    margin-bottom: 30px;
    margin-top: 46px;
    .subtitle {
      margin: 0 10px 0 0;
    }

    &.contacts-end {
      margin-top: 26px;
    }
  }

  .annotation {
    font-size: 12px;
    color: #8a8a8a;
  }

  .template {
    cursor: pointer;
    margin-left: 30px;
    font-size: 14px;
    color: #319ebc;
    text-decoration: underline;
  }

  .image-box {
    width: 90px;
    height: 90px;
    position: relative;
    border-radius: 6px;
    box-sizing: border-box;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      object-fit: cover;
      border: 1px solid #dddddd;
    }

    .icon-box {
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;

      i {
        margin: 0 10px;
        font-size: 18px;
        color: #fff;
      }
    }

    &:hover {
      .icon-box {
        opacity: 1;
      }
    }
  }

  &:deep(.el-upload--picture-card) {
    width: 90px;
    height: 90px;
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:deep(.el-upload-list__item) {
    width: 90px;
    height: 90px;
    border: 1px solid #dddddd;

    img {
      width: 88px;
      height: 88px;
    }
  }
}
.form-box {
  width: 870px !important;
}
.hint-wrap {
  .el-icon-warning {
    margin-right: 6px;
  }
  .el-icon-warning, .hint-text {
    vertical-align: middle;
  }
}
</style>
