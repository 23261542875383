<template>
  <section class="ls-container">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="auth-wrap main-section">
      <el-steps :active="stepActive" align-center>
        <el-step title="选择认证方式"></el-step>
        <el-step title="填写企业信息"></el-step>
        <el-step title="等待审核，完成认证"></el-step>
      </el-steps>

      <div class="info-box">
        <div v-show="stepActive != 3 && authStatus !== 'fail'" class="flex notice">
          <i class="el-icon-warning fail-color"></i>
          <div>
            温馨提示：进行认证时，同一企业或机构信息无法被重复使用；
            <br />
            选择 "我是法人" 的认证方式时，会默认使用当前账号的实名认证信息；如您不是企业法定代表人，请选择 "我是代理人" 进行认证。
          </div>
        </div>

        <!-- 法定代表人信息 -->
        <AuthMethods v-show="stepActive === 1" v-model="workbenchUserRole" @submit="authMethodsNext"></AuthMethods>

        <!-- 企业基本信息和联系人信息 -->
        <EnterpriseInfo
          v-show="stepActive === 2 && authStatus !== 'fail'"
          :workbenchUserRole="workbenchUserRole"
          @submit="enterpriseInfoNext"
        ></EnterpriseInfo>

        <!-- 认证失败 -->
        <div v-show="stepActive === 2 && authStatus === 'fail'" class="flex alignCenter justifyCenter column fail-auth">
          <div class="ls-icons icon-fail"></div>
          <div class="m-t-15 fail-color">企业认证未通过</div>
          <div class="m-t-15">今日剩余可操作次数：{{ tryNum }}</div>
          <div class="m-t-15">很遗憾，您的企业未通过认证。请仔细检查所填写的企业信息是否准确。</div>
          <div class="btns-box">
            <div class="ls-btns" @click="onExamine">返回检查信息</div>
            <div class="ls-btns" @click="toAccount">返回安全中心</div>
          </div>
        </div>

        <!-- 认证中 -->
        <div v-if="stepActive === 3 && authStatus === 'loading'" class="flex alignCenter justifyCenter column loading-auth">
          <div class="ls-loading ls-icons icon-loading"></div>
          <div class="m-t-15 content">企业认证信息正在验证中…</div>
        </div>

        <!-- 认证通过 -->
        <div
          v-if="stepActive === 3 && (authStatus === 'success' || authStatus === 'auditing')"
          class="flex alignCenter justifyCenter column success-auth"
        >
          <div class="ls-icons icon-success"></div>
          <div v-if="authStatus === 'success' && workbenchUserRole == 0" class="m-t-15">企业认证通过</div>
          <template v-else-if="authStatus === 'auditing' || authStatus === 'success'">
            <div class="m-t-15">您的企业认证审核提交成功</div>
            <div class="m-t-15">
              您正在进行企业认证操作。今日剩余可操作次数：{{ tryNum }}
              <br />
              预计审核等待时间：1~2个工作日。
            </div>
          </template>
          <div class="ls-btns back" @click="toAccount">返回安全中心</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/**
 * 企业认证
 */
import BreadCrump from '@/components/common/BreadCrump.vue'
import AuthMethods from '@/components/enterpriseAuth/AuthMethods'
import EnterpriseInfo from '@/components/enterpriseAuth/EnterpriseInfo'
import { uploadFile, submitEnterpriseInfo, verifyEnterpriseNumber } from '@/api/interface/account'
import { mapMutations } from 'vuex'

export default {
  components: {
    BreadCrump,
    AuthMethods,
    EnterpriseInfo
  },
  data() {
    return {
      breadcrumbList: [
        { menuName: '账号信息', routeName: 'Account' },
        { menuName: '认证中心-企业认证', curCss: 'cur' }
      ],
      stepActive: 1,
      authStatus: null,
      workbenchUserRole: 0, //身份类别，0是法人，1是代理人
      tryNum: 5,
      loading: false
    }
  },
  methods: {
    ...mapMutations(['updateUserInfo']),
    // 返回安全中心
    toAccount() {
      this.$router.push('/account')
    },
    // 返回信息样式
    onExamine() {
      this.stepActive = 2
      this.authStatus = null
    },
    // 第一部下一步⌚️
    authMethodsNext() {
      this.stepActive = 2
    },
    // 企业信息验证
    enterpriseInfoNext(res, businessLicenseFile, powerOfAttorneyFile) {
      if (this.loading) return
      this.loading = true
      verifyEnterpriseNumber({
        bizType: this.workbenchUserRole === 0 ? 9930007 : 9930005
      })
        .then(num => {
          this.tryNum = num || 0
          if (!num || num <= 0) {
            this.$message.info('您今日可提交认证次数已用完，请明日恢复后再试。')
          } else {
            if (this.authStatus === 'loading') return
            this.stepActive = 3
            this.authStatus = 'loading'
            if (this.workbenchUserRole === 0) {
              this.legalPerson(res, businessLicenseFile)
            } else if (this.workbenchUserRole === 1) {
              this.agent(res, businessLicenseFile, powerOfAttorneyFile)
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 法人
    legalPerson(data, file) {
      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        uploadFile(formData)
          .then(res => {
            data['businessLicenseUrl'] = res
            delete data['businessLicenseFile']
            this.onSubmitInfo(data)
          })
          .catch(error => {
            console.log(error)
            this.onExamine()
          })
      }
    },
    // 代理人
    agent(data, file1, file2) {
      if (file1 && file2) {
        const formData1 = new FormData()
        formData1.append('file', file1)

        const formData2 = new FormData()
        formData2.append('file', file2)

        Promise.all([uploadFile(formData1), uploadFile(formData2)])
          .then(res => {
            data['businessLicenseUrl'] = res[0]
            delete data['businessLicenseFile']
            data['powerOfAttorneyUrl'] = res[1]
            delete data['powerOfAttorneyFile']
            this.onSubmitInfo(data)
          })
          .catch(error => {
            console.log(error)
            this.onExamine()
          })
      }
    },
    // 认证
    onSubmitInfo(data) {
      submitEnterpriseInfo({ ...data, workbenchUserRole: this.workbenchUserRole })
        .then(res => {
          const { auditTimes, auditStatus } = res || {}
          // 更新store 企业认证状态
          this.updateUserInfo({ auditStatus })
          this.tryNum = auditTimes || 0
          if (auditStatus === 3) {
            // 审核失败
            this.stepActive = 2
            this.authStatus = 'fail'
          } else {
            this.stepActive = 3
            this.authStatus = auditStatus === 1 ? 'auditing' : 'success'
          }
        })
        .catch(error => {
          console.log(error)
          this.onExamine()
        })
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped>
@import '@/scss/el/_step.scss';

.auth-wrap {
  width: 100%;
  margin-top: 16px;
  padding-bottom: 30px;
  background-color: #fff;
  min-height: calc(100vh - 170px);

  .info-box {
    margin: 30px 20% 0 20%;

    .notice {
      margin-left: 63px;
      font-size: 12px;
      line-height: 35px;
      color: #8a8a8a;

      i {
        font-size: 14px;
        margin-top: 11px;
        margin-right: 8px;
      }
    }

    &:deep(.auth-methods) {
      .subtitle {
        width: 162px;
        font-size: 18px;
        color: #1d8db9;
        text-align: right;
      }

      .form-box {
        width: 700px;
      }

      .btn {
        margin-top: 60px;
        margin-left: 250px;
      }

      .w-540 {
        width: 540px;
      }
    }

    .loading-auth,
    .fail-auth,
    .success-auth {
      margin-top: 150px;
      color: #3d3d3d;
      font-size: 14px;
      line-height: 30px;

      .content {
        margin-top: 20px;
        font-size: 16px;
      }

      .ls-icons {
        width: 60px;
        height: 60px;
      }

      .m-t-15 {
        margin-top: 15px;
      }

      .back {
        margin-top: 40px;
      }

      .btns-box {
        margin-top: 40px;

        div {
          &:first-child {
            margin-right: 42px !important;
          }

          &:last-child {
            color: #1d8db9;
            background-color: #fff;
            border: 1px solid #1d8db9;
          }
        }
      }
    }
  }
}

:deep() .el-steps {
  padding-top: 100px;
}
</style>
